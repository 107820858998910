<template>
  <v-icon v-bind="$attrs" v-on="$listeners" :class="buildClasses">
    {{ isLib ? icon : "$" + nameIcon }}
  </v-icon>
</template>
<script>
export default {
  name: "MIcon",
  props: {
    icon: {
      type: String,
      default: null,
      validator: function (value) {
        return typeof value === "string" && value.length > 1;
      },
    },
    className: {
      type: String,
      validator: function (value) {
        return value.length > 1;
      },
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLib() {
      return (
        typeof this.icon === "string" &&
        this.icon.toLowerCase().indexOf("mdi") === 0
      );
    },
    nameIcon() {
      let nameValuesIcon = "";
      if (this.icon) {
        const words = this.icon.split("-");
        for (let i = 0; i < words.length; i++) {
          if (i === 0) {
            nameValuesIcon +=
              words[i].charAt(0).toUpperCase() + words[i].slice(1);
          } else {
            nameValuesIcon +=
              words[i].charAt(0).toUpperCase() +
              words[i].slice(1).toLowerCase();
          }
        }
      }
      return nameValuesIcon;
    },

    buildClasses() {
      const res = ["m-icon"];

      if (!(this.icon === null)) {
        res.push(this.icon);
        res.push(this.icon.split("-")[0] + "-icon-pack");
      }

      if (this.className) {
        res.push(this.className);
      }

      if (this.active) res.push("active");

      return res;
    },
  },
};
</script>
<style>
.v-icon__component svg {
  width: inherit;
  height: inherit;
}

span.medexpert-icon-pack svg {
  width: 20px;
  height: 20px;
}

span.tool-icon-pack svg {
  width: 16px;
  height: 16px;
}

span.check-icon-pack svg {
  width: 24px;
  height: 24px;
}

.active {
  color: #2757ff !important;
}

.v-input__icon--append .m-icon,
.v-input__icon--append .v-icon {
  width: 24px;
  height: 24px;
}

/*
  xSmall = '12px',
  small = '16px',
  default = '24px',
  medium = '28px',
  large = '36px',
  xLarge = '40px'
*/
</style>
