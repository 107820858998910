import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { VTreeSelect } from "vuetify-toolkit/vuetify-toolkit.umd";
import ru from "vuetify/es5/locale/ru";
import "@mdi/font/css/materialdesignicons.css";
Vue.use(Vuetify);

// SVG Sprite
const req = require.context("../assets/icons/", true, /\.svg$/);
let svgModules = null;
const requireAll = (requireContext) => {
  svgModules = requireContext.keys().map(requireContext);
  return svgModules;
};
requireAll(req);

const valuesIcon = {};
svgModules.forEach((element) => {
  const words = element.default.id.split("-");
  let nameValuesIcon = "";
  for (let i = 0; i < words.length; i++) {
    if (i === 0) {
      nameValuesIcon += words[i].charAt(0).toUpperCase() + words[i].slice(1);
    } else {
      nameValuesIcon +=
        words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }
  }

  const namePack = element.default.id.split("-")[0] + "-icon-pack";
  Vue.component(element.default.id, {
    render: function (createElement) {
      return createElement(
        "svg",
        {
          attrs: { "aria-hidden": true },
          class: {
            [namePack]: true,
            [element.default.id]: true,
          },
        },
        [
          createElement("use", {
            attrs: {
              "xlink:href": `#${element.default.id}`,
            },
          }),
        ]
      );
    },
  });

  valuesIcon[nameValuesIcon] = {
    component: element.default.id,
  };
});

export default new Vuetify({
  icons: {
    iconfont: "mdi", // default - only for display purposes
    values: valuesIcon,
  },
  lang: {
    locales: { ru },
    current: "ru",
  },
  VTreeSelect,
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        background: "#F7F7FA",
        primary: "#304FFE",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c",
        "blue-grey": {
          base: "rgb(33, 33, 33)",
          lighten1: "#E1E4F0",
        },
      },
      light: {
        error: { base: "#FB0404", lighten1: "fe7d86" },
        red: { base: "#FB0404", lighten1: "fe7d86" },
        beautify: "#86eae3",
        beautify_dark: "#00a0a0",
        success: "#0CA85D",
        warning: { base: "#FBB500", lighten1: "#FFC805" },
        background: "#F7F7FA",
        primary: "#2757FF",
        green: "#00b050",
        blue: "#2757FF",
        "blue-grey": {
          base: "#64644f",
          lighten1: "#E1E4F0",
          lighten2: "#BBC6F0",
          lighten3: "#5F647B",
        },
      },
    },

    // dark: true,
  },
});
