import Api from "./Api";
import store from "@/store/";
import Cookies from "js-cookie";

let promisRefreshToken = null;
let lastTimeRefreshToken = null;

export default {
  session: {
    update() {
      return Api().put("system/HeartbeatPing");
    },
  },

  async updateTokens(AccessToken, RefreshToken) {
    let res = null;

    if (lastTimeRefreshToken && Date.now() - lastTimeRefreshToken < 5000) {
      return new Promise((resolve) => {
        resolve({
          data: {
            AccessToken: store.state.AccessToken,
            RefreshToken: store.state.RefreshToken,
          },
        });
      });
    }
    if (!promisRefreshToken) {
      res = Api().put("user/UpdateTokens", {
        AccessToken,
        RefreshToken,
      });
      promisRefreshToken = res;
    } else {
      res = promisRefreshToken;
    }

    const { data } = await res;
    lastTimeRefreshToken = Date.now();

    store.commit("SET_TOKEN", data.AccessToken);
    store.commit("SET_REFRESH_TOKEN", data.RefreshToken);

    Cookies.set("AccessToken", data.AccessToken);
    Cookies.set("RefreshToken", data.RefreshToken);

    promisRefreshToken = null;
    return res;
  },
  async login(userName, userPassword, code, userPasswordNew = null) {
    const res = await Api().post("user/authenticate", {
      UserName: userName,
      Password: userPassword,
      OwnerCode: code,
      NewPassword: userPasswordNew,
    });
    const token = res.data.User.AccessToken;
    const refreshToken = res.data.User.RefreshToken;
    res.data.User.AccessToken = null;
    res.data.User.Devices = [];

    const version = res.data.Version;

    store.dispatch("SET_USER", {
      AccessToken: token,
      RefreshToken: refreshToken,
      user: res.data.User,
    });

    if (version) store.commit("sessionManager/SET_VERSION", version);
    if (res?.data?.Organization) {
      store.commit("SET_ORGANIZATION", res?.data?.Organization);
    }
    store.commit("permissions/SET_PERMISSIONS", res.data.Permissions);

    // Save in Cookie
    if (version) Cookies.set("version", JSON.stringify(version));
    if (res?.data?.Organization) {
      Cookies.set("OrganizationName", res?.data?.Organization?.Name);
      Cookies.set("OrganizationOwnerId", res?.data?.Organization?.OwnerId);
      Cookies.set("OrganizationOwnerType", res?.data?.Organization.OwnerType);
    }
    Cookies.set("user", JSON.stringify(res?.data.User));

    Cookies.set("AccessToken", token);
    Cookies.set("RefreshToken", refreshToken);
    Cookies.set("Permissions", JSON.stringify(res.data.Permissions));

    return res;
  },
  async logout() {
    const res = await Api().put("user/terminatesession", {});
    store.dispatch("SET_TOKEN", "");
    Cookies.set("AccessToken", "");
    Cookies.set("RefreshToken", "");
    return res;
  },
  getOwner(code) {
    return Api().get("user/getOwner", {
      params: {
        code,
      },
    });
  },
  updateDevice() {
    return Api().put("user/updateDevice");
  },
};
