const IconsData = {
  // Профиль пользователя
  "view-Profile": "medexpert-users",
  // Избранное
  "view-Favorite": "medexpert-star",
  // Начало работы
  "view-Instructions": "medexpert-file-document-multiple",
  // Главная
  "view-Home": "medexpert-home",
  "view-NewsEdit": "medexpert-history-change",
  "view-news": {
    all: "medexpert-history-change",
    system: "medexpert-history-change",
  },
  // Документы, Создание локального документа, Выбор документа
  "view-LocalDocuments": {
    // Все документы
    default: "medexpert-docs",
    // Все документы
    all: "medexpert-normals-doc",
    // Мои документы
    my: "medexpert-templates",
    // Черновики
    developing: "medexpert-localdoc",
  },
  // Редактирование документа
  "view-LocalDocumentEdit": "medexpert-docs",
  // Шаблоны документа
  "view-DocumentTemplates": "medexpert-other-docs",
  // Редактирование шаблона документа
  "view-DocumentTemplateEdit": "medexpert-other-docs",
  // Все курсы
  "knowledgebase-all": "medexpert-book",
  // Мои курсы
  "knowledgebase-my": "medexpert-book-write",
  // Базовые курсы
  "view-knowledgebase": "medexpert-book-write",
  // Тематики курсов
  "view-CourseTopics": "medexpert-book-write",
  // Редактирование курса
  "knowledgeBase-courseEdit": "medexpert-book-write",
  // Учебный материал, Тест
  "view-TestEdit": "medexpert-file-test",
  // Учебный материал
  "view-MaterialEdit": {
    test: "medexpert-file-test",
    document: "medexpert-file-doc",
    video: "medexpert-file-video",
    word: "medexpert-file-word",
    lesson: "medexpert-file-lesson",
    presentation: "mdi-presentation",
  },
  // Копировать из курса
  CoursesCopy: "medexpert-courses-copy",

  // Проверки
  "view-AuditOperations": {
    default: "medexpert-check",
    all: "medexpert-check",
    my: "medexpert-check",
    external: "medexpert-check",
  },
  // Редактирование проверки
  "view-AuditOperationEdit": "medexpert-check",
  // Выполнение проверки
  "view-CheckOperationRun": "medexpert-check",
  // Анализ результатов проверки
  "view-AnalysisAudit": "medexpert-analysis",
  // Анализ tfoms
  "view-AnalysisTform": "medexpert-statistic",
  // Анализ tfoms
  "view-AnalysisKibmd": "medexpert-statistic",

  // Чек-листы
  "view-CheckLists": "medexpert-checkl",
  // Редактирование чек-листов
  "view-CheckListEdit": "medexpert-checkl",
  // Создание чек листа на основе имеющегося
  "view-CheckListClone": "medexpert-checkl",

  // Показатели качества
  "view-AuditIndicators": "medexpert-quality",
  // Редактирование показателей качества
  "view-AuditIndicatorEdit": "medexpert-quality",

  // Нежелательные события, Журнал НС
  "view-UnwishedEvents": "medexpert-unwished",
  // Редактирование журнала НС
  "view-UnwishedEventEdit": "medexpert-unwished",
  // Виды НС
  "view-UnwishedEventTypes": "medexpert-unwished-types",
  // Редактирование вида НС
  "view-UnwishedEventTypesEdit": "medexpert-unwished-types",
  // Последствия НС
  "view-UnwishedConsequenceTypes": "medexpert-unwished-consequence",
  // Ответственные за результаты нежелательных событий
  "view-UnwishedEventResponsibles": "medexpert-users",
  // Ответственный за результаты нежелательных событий
  "view-UnwishedEventResponsiblesEdit": "medexpert-users",

  // Статистические показатели
  "view-StatisticIndicators": "medexpert-statistic",
  // Редактирование статистических показателей
  "view-StatisticIndicatorEdit": "medexpert-statistic",

  // Медицинские изделия
  "view-Equipments": "medexpert-mdevices",
  // Редактирование медицинских изделий
  "view-EquipmentEdit": "medexpert-mdevices",

  // Анализ
  "view-AnalysisIndicators": "medexpert-analysis",

  // Задачи
  "view-Tasks": {
    default: "medexpert-tasks",
    // Все задачи
    all: "medexpert-tasks",
    // Мои задачи
    my: "medexpert-tasks",
    // Черновики, Прикладные документы
    control: "medexpert-tasks",
  },
  // Редактирование задачи
  "view-TaskEdit": "medexpert-tasks",

  // Планы и проекты
  "view-Projects": {
    all: "medexpert-project-plan",
    plans: "medexpert-plans",
    projects: "medexpert-projects",
  },
  // Редактирование планы и проекты
  "view-ProjectEdit": {
    plans: "medexpert-plans",
    projects: "medexpert-projects",
  },
  // Отчеты
  MoreReports: "medexpert-reports",
  ReportsGroup: "medexpert-reports",
  "view-ConsolidatedReportEdit": "medexpert-reports",
  "view-ConsolidatedReports": "medexpert-reports",
  "report-page": "medexpert-reports",

  // Организация
  Organization: "medexpert-organization",

  // Редактирование организации(реквизиты)
  "view-OrganizationEdit": "medexpert-requisites",

  // Список организаций
  "view-Organizations": "medexpert-organization",

  // Подразделения
  "view-Partitions": "medexpert-subdivisions",
  // Редактирование подразделения
  "view-PartitionEdit": "medexpert-subdivisions",

  // Должности
  "view-Positions": "medexpert-nomenclature",
  "view-PositionEdit": "medexpert-nomenclature",

  // Сотрудники
  "view-Employees": "medexpert-users",
  // Редактирование сотрудников
  "view-EmployeeEdit": "medexpert-users",

  // Стандарты оснащения
  "view-EquipmentStandarts": "medexpert-standards",
  // Редактирование стандартов оснащения
  "view-EquipmentStandartView": "medexpert-standards",

  // Разделы деятельности
  "view-AuditIndicatorCategories": "medexpert-category",
  // Разделы деятельности
  "view-AuditIndicatorCategoriesEdit": "medexpert-category",

  // Контрагенты
  "view-Contragents": "medexpert-counterparties",
  // Редактирование контрагентов
  "view-ContragentEdit": "medexpert-counterparties",

  // Типы прикладных документов
  "view-AppliedDocumentTypes": "medexpert-localdoc",

  // История обновлений системы
  SystemUpdateHistory: "medexpert-history-change",
};

export default IconsData;
