// import Cookies from "js-cookie";
import Vue from "vue";

const state = () => ({
  timer: null,
  lastTimeUpdate: Date.now(),
  interval: 0.5 * 60 * 1000,
  version: {
    BuildVersion: null,
    AppVersion: null,
  },
  ping: 0,
  showConfirm: false,
});

const mutations = {
  SET_SHOW(state, show) {
    state.showConfirm = show;
  },
  SET_VERSION(state, version) {
    state.version = version;
  },
  INC_PING(state) {
    state.ping += 1;
  },
  SET_TIMER(state, timer) {
    if (!state.timer) {
      state.timer = timer;
    }
  },
  UPDATE_LAST_TIME(state) {
    state.lastTimeUpdate = Date.now();
  },
};

const actions = {
  START_TIMER({ state, commit, rootGetters }, api) {
    if (state.timer) return;
    const timer = setInterval(async () => {
      if (state.lastTimeUpdate + state.interval <= Date.now()) {
        try {
          const { data } = await api.update();

          // check version
          // let currentVersion = null;
          // const pars = window.document.head
          //   .querySelector("link[href*='/js/chunk-vendors.'")
          //   .href.match(/vendors\.(.+)\.js$/);
          // if (pars) {
          //   currentVersion = pars[1];
          // }

          if (
            data.BuildVersion !== state.version.BuildVersion &&
            state.ping !== 0
          ) {
            let res = false;
            if (!state.showConfirm) {
              commit("SET_SHOW", true);
              res = await Vue.prototype.$confirm(
                "Сохраните данные, и обновите страницу.<br>" +
                  `<small>${state.version.AppVersion} (${state.version.BuildVersion}) ->  ${data.AppVersion} (${data.BuildVersion})</small>`,
                {
                  title: "Вышло обновление системы",
                  buttonTrueText: "Обновить",
                }
              );
              commit("SET_SHOW", false);
            }
            if (res) {
              const unsavedPages = rootGetters["pageTabs/GET_UNSAVED_PAGES"];
              const getTabName = rootGetters["pageTabs/GET_TAB_FULL_NAME"];
              if (!unsavedPages.length) {
                window.location.reload();
              } else {
                const ht = [];
                for (const i in unsavedPages) {
                  const tab = unsavedPages[i];
                  ht.push(
                    "<small>" +
                      (parseInt(i) + 1) +
                      ". " +
                      getTabName(tab) +
                      "</small>"
                  );
                }
                await Vue.prototype.$confirm(
                  "Сохраните данные, и обновите страницу.<br>" +
                    ht.join("<br>"),
                  {
                    title: "Есть несохраненные данные",
                    buttonTrueText: "Хорошо",
                  }
                );
              }
            }
          } else {
            commit("sessionManager/SET_VERSION", data, { root: true });
          }
        } catch (error) {
          console.log(error);
        } finally {
          commit("INC_PING");
          commit("UPDATE_LAST_TIME");
        }
      }
    }, 2000);
    commit("SET_TIMER", timer);
  },
};
const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
