import axios from "axios";
import store from "@/store/";
import AuthService from "@/services/AuthService";
import Vue from "vue";

export default (headers) => {
  const _axios = axios.create({
    baseURL: process.env.VUE_APP_SERVER_URL, // https://medexpert-webservice-hq3.conveyor.cloud/api/ https://medexpert-api-test.m-pays.ru/api/ http://localhost:60308/api/
    headers: {
      "Content-Type":
        headers && headers["Content-Type"]
          ? headers["Content-Type"]
          : "application/json",
      Authorization: store.state.AccessToken
        ? `Bearer ${store.state.AccessToken}`
        : "",
    },
  });

  // Add a response interceptor
  _axios.interceptors.response.use(
    function (response) {
      // Установка времени последнего обращения к серверку
      store.commit("sessionManager/UPDATE_LAST_TIME");

      return response;
    },
    async function (error) {
      // Проблема с авторизацией
      if (
        error.response.status === 401 &&
        !document.location.pathname.includes("login")
      ) {
        document.location.href = "/login";
        return;
      }
      if (
        error.response.status === 403 &&
        error.response.data.InternalErrorCode !== 114 &&
        !document.location.pathname.includes("login")
      ) {
        document.location.href = "/login";
        return;
      }

      if (
        error.response.status === 403 &&
        error.response.data.InternalErrorCode === 114
      ) {
        try {
          const res = await AuthService.updateTokens(
            store.state.AccessToken,
            store.state.RefreshToken
          );

          error.config.headers.Authorization = "Bearer " + res.data.AccessToken;

          return axios.request(error.config);
        } catch (error) {
          console.log(error);
        }
      } else if (error.response.status === 428) {
        try {
          const ignore = await Vue.prototype.$confirm(
            error.response.data.Warnings.map((e) => e.Text).join("\n"),
            {
              color: "warning",
              type: 1,
              options: error.response.data.Options,
            }
          );

          if (ignore) {
            error.config.data = JSON.stringify({
              ...JSON.parse(error.config.data),
              IgnoreWarningsType: ignore,
            });
            return axios.request(error.config);
          }
        } catch (error) {
          console.log(error);
        }
      } else if (Vue.prototype.$alert) {
        const _oKeys =
          typeof error.response.data === "object"
            ? Object.keys(error.response.data)
            : null;
        if (
          _oKeys?.length &&
          !error.response.data?.Message &&
          !error.response.data.message
        ) {
          Vue.prototype.$alert("Пожалуйста, заполните все обязательные поля");
        } else if (error.response.data.message) {
          Vue.prototype.$alert(error.response.data.message);
        } else
          Vue.prototype.$alert(
            error.response.data?.Message ?? "Что-то то пошло не так ☹"
          );
      }

      return Promise.reject(error);
    }
  );

  // Возможна промежуточная обработка запросов
  return {
    ..._axios,
    put(url, params) {
      return _axios.put(url, params);
    },
    post(url, params) {
      return _axios.post(url, params);
    },
  };
};
