import Api from "../Api";

export default {
  async get(user, archive = false, isOftenUsed = null) {
    return Api().get("document/GetDocumentTemplates", {
      params: { user, archive, isOftenUsed },
    });
  },
  async show(id) {
    return Api().get("document/GetDocumentTemplate", { params: { id } });
  },
  async create(document) {
    return Api().post("document/CreateDocumentTemplate", document);
  },
  async update(document) {
    return Api().put("document/UpdateDocumentTemplate", document);
  },
  async delete(id) {
    const results = [];
    results.push(
      Api().delete("document/DeleteDocumentTemplate", {
        params: { id },
      })
    );
    return Promise.all(results);
  },
  GetOrganizationDocumentTypes() {
    return Api().get("document/GetOrganizationDocumentTypes");
  },
  archive(id) {
    return Api().put("Document/UpdateDocumentTemplateState", {
      id,
    });
  },
};
