const localizeReportNames = {
  ActionPlanReport: `Отчет: "Сводный отчет - таблица"`,
  AuditOperationReport: "Отчет о выполнении проверки",
  ExternalAuditOperationReport: "Отчет о выполнении внешней проверки",
  AuditOperationReportByIndicators:
    "Отчет о выполнении проверки по показателям",
  ExternalAuditOperationReportByIndicators:
    "Отчет о выполнении внешней проверки по показателям",
  AuditOperationObjectsReport: "Отчет о выполнении проверки (Объекты)",
  ExternalAuditOperationObjectsReport:
    "Отчет о выполнении внешней проверки (Объекты)",
  AuditOperationSummaryReport: `Отчет: "Краткий отчет о проверке (с объектами проверки)"`,
  AuditPlanReport: `Отчет: "План проверок"`,
  CheckListReport: `Отчет: "Чек-листа"`,
  ConsolidatedReport: `Отчет: "Сводный отчет (обобщенный)"`,
  ConsolidatedAuditReport: `Отчет: "Сводный отчет по проверкам"`,
  ConsolidatedAuditExternalReport: `Отчет: "Сводный отчет по внешним проверкам"`,
  CorrectiveActionPlanReport: `Отчет: "План корректирующих мероприятий"`,
  CorrectiveActionPlanExecutionReport: `Отчет: "Выполнение плана корректирующих мероприятий"`,
  CourseReport: `Отчет: "Журнал прохождения курса"`,
  DocumentAcquaintanceReport: `Отчет: "Лист ознакомления"`,
  EmployeeReport: `Отчет: "Карточка сотрудника"`,
  EmployeeListReport: `Отчет: "Список сотрудников"`,
  EquipmentReport: `Отчет: "Стандарт оснащения"`,
  EquipmentReestrReport: `Отчет: "Реестр медицинских изделий"`,
  EquipmentStandartExecutionReport: `Отчет: "Выполнение стандарта оснащения"`,
  EquipmentsReport: `Отчет: "Перечень медицинских изделий"`,
  EquipmentsServiceReport: `Отчет: "Перечень мед. изделий, подлежащих тех. обслуживанию"`,
  EquipmentsValidationReport: `Отчет: "Перечень мед. изделий, подлежащих поверке"`,
  FullSummaryReport: `Отчет: "Сводный отчет"`,
  ProjectPlanReport: `Отчет: "План мероприятий"`,
  SummaryReport: `Отчет: "Приложения к сводному отчёту - таблице"`,
  UnwishedEventsReport: `Отчет: "Журнал учета нежелательных событий"`,
  UnwishedEventCardReport: `Отчет: "Карточка нежелательного события"`,
  InspectionPlanReport: `Отчет: "План проверок внутреннего контроля"`,
  ExternalInspectionPlanReport: `Отчет: "План проверок внешнего контроля"`,
};

const localizePageTabs = {
  Home: "Главная",
  Profile: "Профиль сотрудника",
  AuditOperations: {
    all: "Все проверки",
    my: "Мои проверки",
    external: "Внешние проверки",
  },
  AuditOperationEdit: "Проверка",
  CheckOperationRun: "Выполнение проверки",
  MoreReports: "Разные отчеты",
  ReportPage: "Отчет",
  Tasks: {
    all: "Все задачи",
    my: "Мои задачи",
    control: "Задачи на контроле",
  },
  Projects: "Проекты",
  Plans: "Планы",
  ProjectEdit: "Проект",
  PlanEdit: "План мероприятий",
  TaskEdit: "Задача",
  CheckLists: "Чек-листы",
  CheckListEdit: "Чек-лист",
  CheckListClone: "Создание чек-листа",
  OrganizationEdit: "Реквизиты",
  Organizations: "Список организаций",
  Employees: "Сотрудники",
  EmployeeEdit: "Сотрудник",
  Positions: "Должности",
  PositionEdit: "Должность",
  Partitions: "Подразделения",
  PartitionEdit: "Подразделение",
  Equipments: "Медицинские изделия",
  EquipmentEdit: "Мед. изделие",
  EquipmentStandarts: "Стандарты оснащения",
  EquipmentStandartView: "Стандарт осн.",
  LocalDocuments: {
    index: "Локальные документы",
    developing: "Черновики",
    my: "Мои документы",
    all: "Все документы",
  },
  KnowledgeBaseAll: "Курсы",
  KnowledgeBaseView: "Базовые курсы",
  KnowledgeBaseMy: "Мои курсы",
  KnowledgeBaseCourseEdit: "Курс",
  KnowledgeBaseBaseCourseEdit: "Базовый курс",
  KnowledgeBaseCourseEmployeeEdit: "Курс",
  KnowledgeBaseMatarialEdit: "Материал",
  KnowledgeBaseMatarialEmployeeEdit: "Материал",
  KnowledgeBaseTestEdit: "Тестовое задание",
  KnowledgeBaseTestEmployeeEdit: "Тестовое задание",
  LocalDocumentEdit: "Документ",
  DocumentTemplates: "Шаблоны документов",
  DocumentTemplateEdit: "Шаблон документа",
  AppliedDocumentTypes: "Типы прикладных документов",
  KnowledgeBaseSubjects: "Тематики курса",
  Contragents: "Контрагенты",
  ContragentEdit: "Контрагент",
  AnalysisSettingsEdit: "Настройки анализа показателей оценки качества",
  ReportSettingsEdit: "Настройки отчетов",
  AuditIndicators: "Показатели оценки качества",
  AuditIndicatorEdit: "Пок. качества",
  AuditIndicatorTemplates: "Шаблоны показателей оценки качества",
  AuditIndicatorTemplateView: "Шаблон пок. качества",
  AuditIndicatorCategories: "Разделы деятельности",
  AuditIndicatorCategoriesEdit: "Раздел деятельности",
  CheckListTemplates: "Шаблоны чек-листов",
  CheckListTemplateView: "Шаблон чек-листа",
  AnalysisAudit: "Анализ проверки",
  AnalysisTform: "Анализ ТФОМС",

  AnalysisKibmd: "Анализ МО",
  AnalysisIndicators: "Анализ состояния КиБМД",
  UnwishedEventsJournal: "Нежелательные события",
  UnwishedEventEdit: "Неж. событие",
  UnwishedEventResponsibles:
    "Ответственные за результаты нежелательных событий",
  UnwishedEventResponsiblesEdit:
    "Ответственный за результаты нежелательных событий",
  UnwishedEventTypes: "Виды нежелательных событий",
  UnwishedEventTypesEdit: "Вид неж. события",
  UnwishedConsequenceTypes: "Виды последствий нежелательных событий",
  StatisticIndicators: "Статистические показатели",
  StatisticIndicatorEdit: "Стат. показатель",
  News: {
    all: "Новости",
    system: "История обновлений",
  },
  NewsEdit: "Новость",
  Instructions: "Начало работы",
  ConsolidatedReportEdit: "Сводный отчет по ВКК",
  ConsolidatedReports: "Сводные отчеты по ВКК",
};

export { localizeReportNames, localizePageTabs };
